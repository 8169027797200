@font-face {
  font-family: 'Repo';
  font-style: normal;
  font-weight: normal;
  src: local('Repo'), url('assets/fonts/Repo.otf') format('woff');
}

@font-face {
  font-family: 'Repo-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Repo-Bold'), url('assets/fonts/Repo-Bold.otf') format('woff');
}

*,
body {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Repo', sans-serif;
  overflow-x: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
