.arrowTopBtn {
  position: fixed;
  bottom: 60px;
  right: 60px;
  width: 53px;
  height: 53px;
  background: rgba(132, 231, 240, 0.66);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: all 0.3s;
}

.arrowTopBtn:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 500px) {
  .arrowTopBtn {
    width: 35px;
    height: 35px;
  }
}
